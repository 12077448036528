import React from 'react'

const Download = () => {
  return (
    <div className='download-get'>
        <div className='download-get__heading'>Download Pulpit</div>
        <div className='download-get__sub-heading'>Supermarket of rides</div>
        <div className='statisticSection__container download-get__container'>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-customers"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>Active Users</div>
               <div className='statisticSection__num'>1 Million+</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-building"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>Cities Covered</div>
               <div className='statisticSection__num'>200+</div>
           </div>
           <div className='statisticSection__box'>
               <div className='statisticSection__icon-box'>
                 <svg className='statisticSection__icon' aria-hidden="true">
                    <use href="sprite.svg#icon-driver_fill"></use>
                 </svg>
               </div>
               <div className='statisticSection__text margin__top-small'>Taxi Drivers</div>
               <div className='statisticSection__num'>8000+</div>
           </div>
        </div>
        <div className='downloadSection__buttons'>
           <button className='downloadSection__button downloadSection__button--apple'>
            <a className='downloadSection__link' href='https://apps.apple.com/in/app/pulpit-supermarket-of-rides/id6444613022' target="_blank" rel='noreferrer'>
              <svg className='downloadSection__icon' aria-hidden="true">
                <use href="sprite.svg#icon-appleinc"></use>
              </svg>
              <div className='downloadSection__text'>
                <span className='downloadSection__text-primary'>Download on the</span>
                <span className='downloadSection__text-secondary'>App Store</span>
              </div>
            </a>
          </button> 
          <button className='downloadSection__button downloadSection__button--playstore'>
            <a className='downloadSection__link' href='https://play.google.com/store/apps/details?id=com.pulpit.pulpit_customer&pcampaignid=web_share&pli=1' target="_blank" rel='noreferrer'>
            
              <img className='downloadSection__icon' src="./images/icon-google-play.svg" alt='pulpit google play'></img>
              
              <div className='downloadSection__text'>
                <span className='downloadSection__text-primary'>GET IT ON</span>
                <span className='downloadSection__text-secondary'>GOOGLE PLAY</span>
              </div>
            </a>
          </button>
        </div>
    </div>
  )
}

export default Download