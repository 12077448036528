import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { bookNow } from '../../store/Cab/CabAction';
import { cabAction } from '../../store/Cab/CAbReducer';
import { useNavigate } from 'react-router-dom';

const CabCard = ({ showPrice, cab, isWidth=false }) => {
  const dispatch = useDispatch();
  const {bookingSuccess, visitorEntryId, web_trip_unique_id, distance} = useSelector((state)=>state.cab);
  const navigate = useNavigate();

  useEffect(()=>{
    if(bookingSuccess){
      dispatch(cabAction.clearBookingSuccess());
      navigate('/bookCab');
    }
  },[bookingSuccess,dispatch,navigate])
  
  const submitHandle = ()=>{
    const currentTripData = JSON.parse(localStorage.getItem('tripData')) || {};
    
    const updatedTripData = {
      ...currentTripData,
      cab:{...cab}
    };
    
    localStorage.setItem('tripData', JSON.stringify(updatedTripData));

    const data= {
      visitorEntryId,
      web_trip_unique_id,
      vehicle_id: cab?.vehicle_id,
      grand_total:cab?.grand_total,
      advance_booking:20,
      advance_booking_amount:500,
      GST:cab?.gst_percentage,
      GSTCalculate:cab?.gst,
      distance,
      estimate_fare:cab?.subtotal,
    }
    dispatch(bookNow(data))
  }

  return (
    <div className="cab-card">
      <div className={isWidth?"cab-card__image-container cab-card__image-container2":"cab-card__image-container"}>
        <div className="cab-card__image-wrapper">
          <img className="cab-card__image" src={cab?.image} alt='car'></img>
        </div>
      </div>
      <div className={isWidth?"cab-card__details cab-card__details2":"cab-card__details"}>
        <div className="cab-card__model">
          {cab?.name}
        </div>
        <ul className="cab-card__features-list">
          <li className="cab-card__feature-item">
            <svg aria-hidden="true" className="cab-card__icon">
              <use href="sprite.svg#icon-persons"></use>
            </svg>
            <span className="cab-card__feature-text">{cab?.seat_capacity} Seats</span>
          </li>
          <li className="cab-card__feature-item">
            <svg aria-hidden="true" className="cab-card__icon">
              <use href="sprite.svg#icon-speed"></use>
            </svg>
            <span className="cab-card__feature-text">{distance} kms included</span>
          </li>
        </ul>
        
        <ul className="cab-card__additional-info">
        <div className="cab-card__car-type">
          Compact Car
        </div>
          <li className="cab-card__additional-item">
            <svg className='cab-card__icon' aria-hidden="true">
              <use href="sprite.svg#icon-check"></use>
            </svg>
            <div className="cab-card__info-heading">km fare:</div>
            <span className="cab-card__info-text">₹{cab?.fare_per_km}/km</span>
          </li>
          <li className="cab-card__additional-item">
            <svg className='cab-card__icon' aria-hidden="true">
              <use href="sprite.svg#icon-check"></use>
            </svg>
            <div className="cab-card__info-heading">Cancellation:</div>
            <span className="cab-card__info-text">Free till 1 hour of departure</span>
          </li>
          <li className="cab-card__additional-item">
            <svg className='cab-card__icon' aria-hidden="true">
              <use href="sprite.svg#icon-check"></use>
            </svg>
            <div className="cab-card__info-heading">Advance booking:</div>
            <span className="cab-card__info-text">20%</span>
          </li>
        </ul>
      </div>
      {showPrice && (
        <div className="cab-card__price-section">
          <div className="cab-card__price">₹ {parseInt(cab?.subtotal)}</div>
          <div className="cab-card__taxes">+ {parseInt(cab?.gst)} (Taxes & Charges)</div>
          <button className="cab-card__button" onClick={submitHandle}>Book Now</button>
        </div>
      )}
    </div>
  )
}

export default CabCard