import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { applyCoupon, createOrder } from '../../../store/Cab/CabAction';
import { cabAction } from '../../../store/Cab/CAbReducer';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from "@mui/material";
import { useNavigate } from 'react-router-dom'; // Import useNavigate for redirection

const PaymentCard = ({ tripData }) => {
  // Payment option and coupon state
  const [paymentOption, setPaymentOption] = useState('complete'); // 'complete' or 'partial'
  const [couponCode, setCouponCode] = useState('');
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [offerAmount, setOfferAmount] = useState(0);
  // Dialog state – false by default so that the dialog shows only after payment succeeds.
  const [isOpen, setIsOpen] = useState(false);

  // Calculation of amounts
  const finalAmount = Number(parseInt(tripData?.cab?.total));
  const partialAmount = Number(parseInt(tripData?.cab?.total * 0.2));
  const subAmount = Number(parseInt(tripData?.cab?.subtotal));
  const taxAmount = Number(parseInt(tripData?.cab?.gst));

  const dispatch = useDispatch();
  const { couponApplied, couponAppliedSuccess, visitorEntryId, couponAppliedError, orderSuccess, order } = useSelector(
    (state) => state.cab
  );

  const navigate = useNavigate(); // Hook for redirection

  const handlePaymentOptionChange = (e) => {
    setPaymentOption(e.target.value);
  };

  // Listen for coupon status changes
  useEffect(() => {
    if (couponAppliedSuccess) {
      setIsCouponApplied(true);
      setOfferAmount(parseInt(couponApplied?.discount));
      dispatch(cabAction.clearCouponAppliedSuccess());
    }
    if (couponAppliedError) {
      alert('Invalid Coupon Code');
      dispatch(cabAction.clearCouponAppliedError());
    }
  }, [couponAppliedSuccess, couponApplied, couponAppliedError, dispatch]);

  // Create order and open Razorpay on orderSuccess, then open the dialog on successful payment.
  useEffect(() => {
    if (orderSuccess) {
      const options = {
        key: order.key_id,
        amount: order.amount,
        currency: 'INR',
        name: order.name,
        description: 'Pulpit Cab Booking',
        order_id: order.order_id,
        handler: function (response) {
          // Payment successful—open the dialog
          setIsOpen(true);
        },
        prefill: {
          name: order.name,
          email: order.email,
          contact: order.contact,
        },
        theme: {
          color: '#21A5A0',
        },
      };

      if (window.Razorpay) {
        const rzp = new window.Razorpay(options);
        rzp.open();
        rzp.on('payment.failed', function (response) {
          alert(`Payment failed. Error: ${response.error.description}`);
        });
      } else {
        alert('Razorpay is not loaded. Please try again later.');
      }
    }
  }, [orderSuccess, order]);

  // Cleanup order success on component unmount if needed.
  useEffect(() => {
    return () => {
      if (orderSuccess) {
        dispatch(cabAction.clearOrderSuccess());
      }
    };
  }, [orderSuccess, dispatch]);

  // Coupon apply and remove handlers
  const handleCouponApply = () => {
    const data = {
      visitorEntryId: visitorEntryId,
      coupon_for:
        tripData?.trip_type === 3 ? 'OutStation' : tripData?.trip_type === 2 ? 'Rental' : 'Local',
      code: couponCode,
      grand_total: parseInt(tripData?.cab?.total),
    };
    dispatch(applyCoupon(data));
  };

  const handleCouponRemove = () => {
    setIsCouponApplied(false);
    setOfferAmount(0);
  };

  // Create order on clicking pay
  const handlePay = () => {
    const savedTripData = JSON.parse(localStorage.getItem('tripData'));
    if (
      savedTripData.first_name &&
      savedTripData.last_name &&
      savedTripData.email &&
      savedTripData.mobile_number
    ) {
      const data = {
        visitorEntryId: visitorEntryId,
        first_name: savedTripData.first_name,
        last_name: savedTripData.last_name,
        email: savedTripData.email,
        phone_number: '+91' + savedTripData.mobile_number,
        payment_amount: (finalAmount - offerAmount) * 100,
        advance_booking: paymentOption === 'complete' ? 100 : 20,
        advance_booking_amount:
          paymentOption === 'complete'
            ? (finalAmount - offerAmount) * 100
            : partialAmount * 100,
        GST: Number(parseInt(tripData?.cab?.gst_percentage)),
        GSTCalculate: Number(parseInt(tripData?.cab?.gst)),
        is_full_payment: paymentOption === 'complete' ? 1 : 0,
      };
      dispatch(createOrder(data));
    } else {
      alert('Please fill the form correctly.');
    }
  };

  return (
    <>
      <div className="payment-card">
        <h2 className="payment-card__heading">Payment Details</h2>

        {/* Payment Option */}
        <div className="payment-card__options">
          <label className="payment-card__label">
            <input
              type="radio"
              value="complete"
              className="payment-card-radio"
              checked={paymentOption === 'complete'}
              onChange={handlePaymentOptionChange}
            />
            <div className="payment-card__para">Make full payment now</div>
            <div className="payment-card__amount2">₹{finalAmount}</div>
          </label>
          <label className="payment-card__label">
            <input
              type="radio"
              value="partial"
              className="payment-card-radio"
              checked={paymentOption === 'partial'}
              onChange={handlePaymentOptionChange}
            />
            <div className="payment-card__para">
              Make 20% payment now
              <span>Pay remaining to the driver</span>
            </div>
            <div className="payment-card__amount2">₹{partialAmount}</div>
          </label>
        </div>

        {/* Coupon Application */}
        <div className="payment-card__coupon">
          {!isCouponApplied ? (
            <>
              <input
                type="text"
                value={couponCode}
                placeholder="Enter coupon code"
                className="payment-card__coupon-input"
                onChange={(e) => setCouponCode(e.target.value)}
              />
              <button className="payment-card__coupon-button" onClick={handleCouponApply}>
                Apply
              </button>
            </>
          ) : (
            <div className="payment-card__coupon-applied">
              <span className="payment-card__para">Coupon Applied</span>
              <button onClick={handleCouponRemove} className="payment-card__coupon-remove">
                {couponCode} ✖
              </button>
            </div>
          )}
        </div>

        {/* Totals */}
        <div className="payment-card__total--sub">
          <div className="payment-card__para">Base Amount:</div>
          <div className="payment-card__amount">₹{subAmount}</div>
        </div>
        <div className="payment-card__total--sub">
          <div className="payment-card__para">Tax:</div>
          <div className="payment-card__amount">₹{taxAmount}</div>
        </div>
        {isCouponApplied && (
          <div className="payment-card__offer">
            <p className="payment-card__para">Offer Applied:</p>
            <div className="payment-card__amount">₹{offerAmount}</div>
          </div>
        )}
        {paymentOption === 'partial' && (
          <div className="payment-card__total">
            <div className="payment-card__para">20% Amount:</div>
            <div className="payment-card__amount">₹{partialAmount}</div>
          </div>
        )}
        <div className="payment-card__total">
          <div className="payment-card__para">
            Total Amount: <span>inc. of tolls and taxes</span>
          </div>
          <div className="payment-card__amount">₹{finalAmount - offerAmount}</div>
        </div>

        {/* Pay Button */}
        <button onClick={handlePay} className="payment-card__pay-button">
          Pay ₹{paymentOption === 'complete' ? finalAmount - offerAmount : partialAmount}
        </button>
      </div>

      {/* Dialog for Successful Payment */}
      <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
        <Dialog.Overlay className="dialog__overlay" />
        <Dialog.Content className="dialog__content">
          {/* Animated Green Checkmark */}
          <div className="dialog__icon-container">
            <svg
              className="dialog__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="dialog__icon-circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="dialog__icon-check"
                fill="none"
                d="M14 27 l7 7 l16 -16"
              />
            </svg>
          </div>
          <Dialog.Title className="dialog__title">Payment Successful</Dialog.Title>
          <Dialog.Description className="dialog__description">
            We will get back to you with the Driver's details soon.
          </Dialog.Description>
          <Button
            onClick={() => {
              setIsOpen(false);
              navigate('/'); // Redirect to home page
            }}
            className="dialog__button"
          >
            Okay
          </Button>
        </Dialog.Content>
      </Dialog.Root>
    </>
  );
};

export default PaymentCard;
